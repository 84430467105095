
import { defineComponent } from "vue";
import CompoundForm from "./Form.vue";

export default defineComponent({
  components: {
    CompoundForm,
  },
  setup() {
    return {};
  },
});
